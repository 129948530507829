/* eslint-disable react/no-multi-comp */
import Table from 'aws-northstar/components/Table';
import StatusIndicator from 'aws-northstar/components/StatusIndicator';
import Button from 'aws-northstar/components/Button';
import Inline from 'aws-northstar/layouts/Inline';
import React  from 'react';
import { connect } from 'react-redux' 
import Link from 'aws-northstar/components/Link';
// import DeployEndpoint from './DeployEndpoint'

import axios from 'axios'
import {withTranslation} from 'react-i18next'


const mapStateToProps = state => {
    return { session: state.session }
}
  
const MapDispatchTpProps = (dispatch) => {
    return {
        changeLang: (key)=>dispatch({type: 'change_language',data: key})
    }
}

const columnDefinitions = [
    // {
    //     'id': 'trainingjob.name',
    //     width: 250,
    //     show:false,
    //     Header: 'JobName',
    //     accessor: 'jobname'
    // },
    {
        'id': 'stage',
        width: 120,
        Header: 'Stage',
        accessor: 'stage'
    },
    {
        'id': 'trainingjob.status',
        width: 120,
        Header: 'JobStatus',
        Cell: ({ row  }) => {
            if (row && row.original) {
                // console.log(row.original)
                const status = row.original.status;
                switch(status) {
                    case 'InProgress':
                        return <StatusIndicator  statusType="info">In Procress</StatusIndicator>;
                    case 'Error':
                        return <StatusIndicator  statusType="negative">Error</StatusIndicator>;
                    case 'Completed':
                        if (row.original.model_name){
                            // const model_id = row.original.model_name;
                            // const target = "/TrainingResult/"+model_id;
                            return <div >
                                            <StatusIndicator  statusType={"positive"} >Complete</StatusIndicator>                             
                                    </div>
                        }else{
                            return <div>
                                        <StatusIndicator  statusType="positive" >Complete</StatusIndicator>
                                    </div>;                     
                        }
                    default:
                        return null;
                }
            }
            return null;
        }
    },
    {
        'id': 'trainingjob.result',
        width: 40,
        Header: 'Result',
        Cell: ({row}) => {
            if (row && row.original) {
                // console.log(row.original)
                const status = row.original.status;
                switch(status) {
                    case 'Completed':
                        if (row.original.model_name){
                            const model_id = row.original.model_name;
                            const target = "/TrainingResult/"+model_id;
                            return <div >                          
                                        <Link href={target}><Button variant="icon" icon="folder" /> </Link>
                                    </div>
                        }else{
                            return <div>
                                        <StatusIndicator  statusType="positive">Complete</StatusIndicator>
                                    </div>;                     
                        }
                    default:
                        return null;
                }
            }
            return null;
        }
    },
    // {
    //     'id': 'trainingjob.creation_time',
    //     width: 150,
    //     Header: 'JobCreate',
    //     accessor: 'creation_time'
    // }, 
    // {
    //     'id': 'trainingjob.training_start_time',
    //     width: 150,
    //     Header: 'JobStart',
    //     accessor: 'training_start_time'
    // }, 
    // {
    //     'id': 'trainingjob.training_end_time',
    //     width: 150,
    //     Header: 'JobEnd',
    //     accessor: 'training_end_time'
    // },
    {
        'id': 'model.model_name',
        width: 150,
        Header: 'ModelName',
        accessor: 'model_name'
    },  
    // {
    //     'id': 'model.creation_time',
    //     width: 150,
    //     Header: 'ModelCreate',
    //     accessor: 'model_creation_time'
    // },     
    {
        'id': 'trainingjob.model_data_url',
        width: 400,
        Header: 'ModelStorage',
        accessor: 'model_data_url'
    },
    // {
    //     'id': 'endpointconfig_create_time',
    //     width: 150,
    //     Header: 'EP_Conf_Name',
    //     accessor: 'endpointconfig_create_time'
    // },
    // {
    //     'id': 'endpoint_name',
    //     width: 400,
    //     Header: 'EP_Name',
    //     accessor: 'endpoint_name'
    // },
    // {
    //     'id': 'endpoint_create_time',
    //     width: 150,
    //     Header: 'EP_Create_Time',
    //     accessor: 'endpoint_create_time'
    // },
    {
        'id': 'endpoint_status',
        width: 120,
        Header: 'EP_Status',
        accessor: 'endpoint_status',
        Cell: ({ row  }) => {
            if (row && row.original) {
                // console.log(row.original)
                const status = row.original.endpoint_status;
                switch(status) {
                    case 'InService':
                        return <StatusIndicator  statusType="positive">Ready</StatusIndicator>;
                    default:
                        return null;
                }
            }
            return null;
        }
    },
    // {
    //     'id': 'deploy',
    //     width: 120,
    //     Header: 'deploy',
    //     Cell: ({ row  }) => {
    //         if (row && row.original) {
    //             // console.log(row.original)
    //             const status = row.original.endpoint_status;
    //             switch(status) {
    //                 case 'InService':
    //                     return <DeployEndpoint ep_name={row.original.endpoint_name} disabled={false} />
    //                 default:
    //                     // return <DeployEndpoint  disabled={true} />
    //                     return <DeployEndpoint  disabled />
    //             }
    //         }
    //         return null;
    //     }
    // },
    // {
    //     'id': 'endpoint_last_modified_time',
    //     width: 150,
    //     Header: 'EP_Modified_Time',
    //     accessor: 'endpoint_last_modified_time'
    // },
]


class  TrainTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        loading : true ,
        model_list : [],
        curent : {}
    }
  }

  componentDidMount(){
    // console.log(this.model_list)
    this.setState({loading:true},()=>{
        this.load_data()
    })
  }

  async load_data(){
    // console.log(this.state)

    await axios.get('/listmodel', {dataType: 'json'}).then(res => {
        // console.log(res)
        if (res.data){
            // console.log(res.data)
            var _tmp_data = []
            res.data.forEach((item)=>{
                var _tmp = {}
                _tmp['jobname'] = item['trainingjob.name']
                _tmp['status'] = item['trainingjob.status']
                _tmp['model_data_url'] = item['trainingjob.model_data_url']
                _tmp['creation_time'] = item['trainingjob.creation_time']
                _tmp['training_start_time'] = item['trainingjob.training_start_time']
                _tmp['training_end_time'] = item['trainingjob.training_end_time']

                _tmp['endpoint_name'] = item['endpoint.endpoint_name']
                _tmp['endpoint_create_time'] = item['endpoint.creation_time']
                _tmp['endpointconfig_create_time'] = item['endpointconfig.creation_time']
                _tmp['endpoint_status'] = item['endpoint.status']
                _tmp['endpoint_last_modified_time'] = item['endpoint.last_modified_time']
                _tmp['stage'] = item['stage']
                _tmp['model_name'] = item['model_name']
                _tmp['model_creation_time'] = item['model.creation_time']

                _tmp_data.push(_tmp)
            });

            this.setState({model_list:_tmp_data},()=>{
                this.setState({loading:false})
            })
        }
        // console.log(this.state.model_list)

        return res.data
    })
  }




  jump_to_newTask(){
    this.props.history.push("/NewTrainingTask")
  }

  jump_to_result(){
    this.props.history.push("/TrainingResult")
  }

  render(){
    const {
        props: {t}
      } = this;

    const tableActions = (
        <Inline>
            <Button variant="primary" onClick={() => this.jump_to_newTask()}>
                {t("New Training")}
            </Button>
        </Inline>
    );

    return(
        <Table
            id = "TrainTable"
            actionGroup={tableActions}
            tableTitle={t("Model Training Table")}
            multiSelect={false}
            columnDefinitions={columnDefinitions}
            items={this.state.model_list}
            onSelectionChange={(item)=>{this.setState({curent:item})}}
            loading={this.state.loading}
            disableSettings={false}
        />
    )
  }
}

export default connect(mapStateToProps,MapDispatchTpProps)(withTranslation()(TrainTable));
