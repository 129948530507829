import Table from 'aws-northstar/components/Table';
// import StatusIndicator from 'aws-northstar/components/StatusIndicator';
import Button from 'aws-northstar/components/Button';
import Inline from 'aws-northstar/layouts/Inline';

import React  from 'react';
import { connect } from 'react-redux' 

import axios from 'axios'

import {withTranslation} from 'react-i18next'
const mapStateToProps = state => {
    return { session: state.session }
  }
  const MapDispatchTpProps = (dispatch) => {
    return {
        changeLang: (key)=>dispatch({type: 'change_language',data: key})
    }
  }

const columnDefinitions = [
    {
        'id': 'Deployment_ID',
        width: 300,
        Header: 'ID',
        accessor: 'Deployment_ID'
    },
    {
        'id': 'Device_ID',
        width: 300,
        Header: 'Device',
        accessor: 'Device_ID'
    },
    {
        'id': 'Camera_ID',
        width: 300,
        Header: 'Camera',
        accessor: 'Camera_ID'
    },
    // {
    //     'id': 'Component_Version_ID',
    //     width: 100,
    //     Header: 'Component ID',
    //     accessor: 'Component_Version_ID'
    // },
    // {
    //     'id': 'Model_Version_ID',
    //     width: 100,
    //     Header: 'Model',
    //     accessor: 'Model_Version_ID'
    // },
    {
        'id': 'targetArn',
        width: 500,
        Header: 'Arn',
        accessor: 'targetArn'
    },
    {
        'id': 'deploymentName',
        width: 100,
        Header: 'Name',
        accessor: 'deploymentName'
    },
    // {
    //     'id': 'components',
    //     width: 600,
    //     Header: 'CFG-Component',
    //     accessor: 'components'
    // },
    // {
    //     'id': 'deploymentPolicies',
    //     width: 600,
    //     Header: 'CFG-Policy',
    //     accessor: 'deploymentPolicies'
    // },
    // {
    //     'id': 'iotJobConfigurations',
    //     width: 600,
    //     Header: 'CFG-IoTJob',
    //     accessor: 'iotJobConfigurations'
    // },
]


class  DeploymentCfgTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        loading : true ,
        job_list : [],
        curent : {}
    }
  }

  componentDidMount(){
    // console.log(this.model_list)
    this.setState({loading:true},()=>{
        this.load_data()
    })
  }

  async load_data(){
    await axios.get('/deployment', {dataType: 'json'}).then(res => {
        console.log(res)
        if (res.data){
            console.log(res.data)
            var _tmp_data = []
            res.data.forEach((item)=>{
                var _tmp = {}
                _tmp['Deployment_ID'] = item['Deployment_ID']
                _tmp['Device_ID'] = item['Device_ID']
                if (typeof(item['Camera_ID'])=='object') {
                    _tmp['Camera_ID'] = JSON.stringify(item['Camera_ID'])
                } else {
                    _tmp['Camera_ID'] = item['Camera_ID']
                }
                
                // _tmp['Component_Version_ID'] = item['Component_Version_ID']
                // _tmp['Model_Version_ID'] = item['Model_Version_ID']
                _tmp['targetArn'] = item['targetArn']
                _tmp['deploymentName'] = item['deploymentName']
                // _tmp['components'] = item['components']
                // _tmp['deploymentPolicies'] = item['deploymentPolicies']
                // _tmp['iotJobConfigurations'] = item['iotJobConfigurations']
                
                _tmp_data.push(_tmp)
            });

            this.setState({job_list:_tmp_data},()=>{
                this.setState({loading:false})
            })
        }
        // console.log(this.state.model_list)
        return res.data
    })
  }

  jump_to_newCfg(){
    this.props.history.push("/NewDeployConfig")
  }



  render(){
    const {
        props: {t}
      } = this;
      
    const tableActions = (
        <Inline>
            <Button variant="primary" onClick={() => this.jump_to_newCfg()}>
                {t('New Deployment')}
            </Button>
        </Inline>
    );

    return(

        <Table
            id = "DepCfgTable"
            actionGroup={tableActions}
            tableTitle={t('Deployment Config')}
            columnDefinitions={columnDefinitions}
            items={this.state.job_list}
            onSelectionChange={(item)=>{this.setState({curent:item})}}
            // getRowId={this.getRowId}
            loading={this.state.loading}
            disableSettings={false}
            // onFetchData={this.handleFetchData}
        />
    )
  }
}

export default connect(mapStateToProps,MapDispatchTpProps)(withTranslation()(DeploymentCfgTable));


