
import Form from 'aws-northstar/components/Form';
import Button from 'aws-northstar/components/Button';
import FormField from 'aws-northstar/components/FormField'
import FormSection from 'aws-northstar/components/FormSection';
import Input from 'aws-northstar/components/Input';
import Select from 'aws-northstar/components/Select';
import Multiselect from 'aws-northstar/components/Multiselect';

import Modal from 'aws-northstar/components/Modal';
import axios from 'axios';

import React  from 'react';
import { connect } from 'react-redux' 
import Textarea from 'aws-northstar/components/Textarea';

import {v4 as uuidv4} from 'uuid';

// import mockData from '../mock/mockData'

import {withTranslation} from 'react-i18next'

const mapStateToProps = state => {
  return { session: state.session ,language: state.lang.language, languageList: state.lang.languageList}
}

const MapDispatchTpProps = (dispatch) => {
  return {
      changeLang: (key)=>dispatch({type: 'change_language',data: key})
  }
}


class  NewDeployForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Deployment_ID:uuidv4(),
      Device_ID:'111',
      Camera_ID:'222',
      // Component_Version_ID:'333',
      // Model_Version_ID:'444',
      targetArn:'arn:aws:iot:region:account-id:thing/thingName',
      deploymentName:'Cam_1 Deployment',
      components:JSON.stringify({"componentVersion": "1.0.0", "configurationUpdate": {"reset": [ "/network/useHttps", "/tags" ], "merge": {"tags":["/boiler/1/temperature","/boiler/1/pressure","/boiler/2/temperature","/boiler/2/pressure"]}}}),
      deploymentPolicies:JSON.stringify({"componentUpdatePolicy": {"action": "NOTIFY_COMPONENTS", "timeoutInSeconds": 30 }, "configurationValidationPolicy": {"timeoutInSeconds": 60 }, "failureHandlingPolicy": "ROLLBACK" }),
      iotJobConfigurations:JSON.stringify({"abortConfig": {"criteriaList": [ {"action": "CANCEL", "failureType": "ALL", "minNumberOfExecutedThings": 100, "thresholdPercentage": 5 } ] }, "jobExecutionsRolloutConfig": {"exponentialRate": {"baseRatePerMinute": 5, "incrementFactor": 2, "rateIncreaseCriteria": {"numberOfNotifiedThings": 10, "numberOfSucceededThings": 5 } }, "maximumPerMinute": 50 }, "timeoutConfig": {"inProgressTimeoutInMinutes": 5 } }),
      visible:false,
      post_result:'',
      deviceOptions:[],
      deviceSelectedOption:'',
      cameraOptions:[],
      cameraSelectedOption:'',
      loading : true ,
    }
  }


  componentDidMount(){
    // console.log(this.model_list)
    this.setState({loading:true},()=>{
      this.load_device_data()
      this.load_camera_data()
    })
  }

  componentWillUnmount(){

  }

  async load_device_data(){
    await axios.get('/cfg_device', {dataType: 'json'}).then(res => {
    // await axios.get('/test_cors', {dataType: 'json'}).then(res => {
        console.log(res)
        if (res.data){
            console.log(res.data)
            var _tmp_data = []
            res.data.forEach((item)=>{
                var _tmp = {}
                _tmp['value'] = item['device_id']
                _tmp['label'] = item['device_id'] + ' | ' + item['device_name']
                _tmp['core_arn'] = item['core_arn']
                _tmp_data.push(_tmp)
            });
            this.setState({deviceOptions:_tmp_data},()=>{
                this.setState({loading:false})
            })
        }
        // console.log(this.state.model_list)
        return res.data
    })
  }

  async load_camera_data(){
    await axios.get('/cfg_camera', {dataType: 'json'}).then(res => {
        console.log(res)
        if (res.data){
            console.log(res.data)
            var _tmp_data = []
            res.data.forEach((item)=>{
                var _tmp = {}
                _tmp['value'] = item['camera_id']
                _tmp['label'] = item['description'] + ' | ' + item['location']
                _tmp_data.push(_tmp)

            });
            this.setState({cameraOptions:_tmp_data},()=>{
                this.setState({loading:false})
            })
        }
        // console.log(this.state.model_list)
        return res.data
    })
  }

  submit(){
    // console.log(e)
    const payload = {
      "Deployment_ID":this.state.Deployment_ID,
      "Device_ID": this.state.Device_ID,
      "Camera_ID": this.state.Camera_ID,
      // "Component_Version_ID": this.state.Component_Version_ID,
      // "Model_Version_ID": this.state.Model_Version_ID,
      "targetArn": this.state.targetArn,
      "deploymentName":  this.state.deploymentName,
      "components": this.state.components,
      "deploymentPolicies": this.state.deploymentPolicies,
      "iotJobConfigurations": this.state.iotJobConfigurations,

    };

    console.log(payload);
    const HEADERS = {'Content-Type': 'application/json', 'x-api-key': 'ApiGatewayRestApiDeploymentApiGatewayRestApiDeployment'};
    const apiUrl = '/deployment';
    // var result = "=> call"  + apiUrl + "\n";
    var result = "";

    axios({ method: 'POST', url: `${apiUrl}`, data: payload ,headers: HEADERS}).then(response => {
        console.log(response);
        if (response.status === 200) {
            result = "Post Deployment request successfully !"
        } else {
            result = "Post Deployment request  error !"
        }
        this.setState({post_result:result},()=>{
          this.setState({visible:true})
        })
        // console.log(result)
    })

    this.setState({visible:true})
    // this.props.history.push("/")
  }

  closeModel(){
    this.setState({visible:false})
    this.props.history.push("/DeployConfig")
  }

  handelInputChange(e,key){
    this.setState({[key]:e})
  }

  handleTextChange(e,key){
    this.setState({[key]:e.target.value})
  }

  handleDeviceChange(e){
    let _device = this.state.deviceOptions.find(item => item.value === e.target.value)

    this.setState({
      Device_ID:e.target.value,
      targetArn:_device.core_arn,
      deviceSelectedOption:{value: e.target.value}
    })
  }

  handleCameraChange(value,key){
    var _cameras = []
    value.forEach((item)=>{
      _cameras.push(item['value'])
    });
    this.setState({
      [key]:_cameras
    })
  }

  render(){
    const {
      props: {t}
    } = this;

    return(
      <div>
        <Form
            actions={
                <div>
                    {/* <Button variant="link">Cancel</Button> */}
                    <Button variant="primary" onClick={() => this.submit()}  >Submit</Button>
                </div>
            }
            onSubmit={console.log}
        >
            <FormSection header={t("New Deployment")}>
                 <FormField label="Deployment ID" controlId="formFieldId0">
                    <Input type="text" controlId="input_dep_id" value={this.state.Deployment_ID} readonly />
                </FormField>

                <FormField label="Device ID" controlId="formFieldId1">
                  <Select placeholder={'Choose device'} 
                      statusType={this.state.job_loading} 
                      options={this.state.deviceOptions}
                      selectedOption={this.state.deviceSelectedOption}
                      onChange={(e)=> this.handleDeviceChange(e)}
                  />
                    {/* <Input type="text" controlId="input_device_id" value={this.state.Device_ID} onChange={(e)=> this.handelInputChange(e,'Device_ID')}  /> */}
                </FormField>

                <FormField label="Camera ID" controlId="formFieldId2">
                  <Multiselect placeholder={'Choose camera'}
                    className="basic-multi-select" 
                    statusType={this.state.job_loading} 
                    options={this.state.cameraOptions}
                    onChange={(value)=> this.handleCameraChange(value,'Camera_ID')}
                  />
                    {/* <Input type="text" controlId="input_camera_id" value={this.state.Camera_ID} onChange={(e)=> this.handelInputChange(e,'Camera_ID')}  /> */}
                </FormField>

                {/* <FormField label="Component Version ID" controlId="formFieldId3">
                    <Input type="text" controlId="input_cv_id" value={this.state.Component_Version_ID} onChange={(e)=> this.handelInputChange(e,'Component_Version_ID')}  />
                </FormField>

                <FormField label="Model Version ID" controlId="formFieldId4">
                    <Input type="text" controlId="input_mv_id" value={this.state.Model_Version_ID} onChange={(e)=> this.handelInputChange(e,'Model_Version_ID')}  />
                </FormField> */}

                {/* <FormField label="Target Arn" controlId="formFieldId5">
                    <Input type="text" controlId="input_targetArn" value={this.state.targetArn} onChange={(e)=> this.handelInputChange(e,'targetArn')}  />
                </FormField> */}

                <FormField label="Deployment Name" controlId="formFieldId6">
                    <Input type="text" controlId="input_dn" value={this.state.deploymentName} onChange={(e)=> this.handelInputChange(e,'deploymentName')}  />
                </FormField>

                <FormField label="Components" controlId="formFieldId7">
                  <Textarea classname="LabelText" rows="10"  readonly={false} value={this.state.components} onChange={(e)=>this.handleTextChange(e,'components')}> </Textarea> 
                </FormField>

                <FormField label="Deployment Policies" controlId="formFieldId7">
                  <Textarea classname="LabelText" rows="10"  readonly={false} value={this.state.deploymentPolicies} onChange={(e)=>this.handleTextChange(e,'deploymentPolicies')}> </Textarea> 
                </FormField>

                <FormField label="IoT Job Configurations" controlId="formFieldId7">
                  <Textarea classname="LabelText" rows="10"  readonly={false} value={this.state.iotJobConfigurations} onChange={(e)=>this.handleTextChange(e,'iotJobConfigurations')}> </Textarea> 
                </FormField>

            </FormSection>
        </Form>
        <Modal title="Modal" visible={this.state.visible} onClose={() => this.closeModel()}>
            {this.state.post_result}
        </Modal>
      </div>
    )
  }
}


export default connect(mapStateToProps,MapDispatchTpProps)(withTranslation()(NewDeployForm));


// Deployment_ID
// Device_ID
// Camera_ID
// Component_Version_ID
// Model_Version_ID
// targetArn
// deploymentName
// components
// deploymentPolicies
// iotJobConfigurations

